import { ref } from '@vue/composition-api'
import store from '@/store'
import moduleStoreModule from '../moduleStoreModule'
// import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useModulesForm() {
  const MODULE_APP_STORE_MODULE_NAME = 'app-module'

  // Register module
  if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

  const blankModuleData = {
    operation: 'C',
    description: null,
    descriptionAr: null,
    iconUrl: '',
    status: null,
  }

  const moduleData = ref(JSON.parse(JSON.stringify(blankModuleData)))

  // Call System Codes
  // const { callStatus } = useSystemCodes()
  // callStatus()

  const updateIcon = base64 => {
    //  Obj caveat; doesnt update if the key is not present
    moduleData.value = { ...moduleData.value, icon: base64 }
  }

  const removeIcon = () => {
    moduleData.value = { ...moduleData.value, icon: null }
  }

  return {
    //  Data
    moduleData,

    updateIcon,
    removeIcon,
  }
}
