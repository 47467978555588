<template>
  <b-row>

    <!-- Description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="module-description"
        >
          <b-form-input
            id="module-description"
            v-model="moduleData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description AR-->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description AR"
        rules="required"
      >
        <b-form-group
          label="Description AR"
          label-for="module-description"
        >
          <b-form-input
            id="module-description-ar"
            v-model="moduleData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="Description AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Status -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Status"
          label-for="status"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="moduleData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="status"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      cols="6"
      class="mb-2"
    >
      <s-3-image-uploader
        :accept="'.jpg, .png, .gif'"
        :obj="moduleData"
        :field-name="'iconUrl'"
        :label="'Icon'"
        :directory="'module_icons'"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { statusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import S3ImageUploader from '@/components/s3/s3-image-uploader/s3ImageUploader.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,

    //  Form Validation
    ValidationProvider,

    S3ImageUploader,
  },
  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      emit('update-icon', base64)
    })

    const removeIcon = () => { emit('remove-icon') }

    const {
      getValidationState,
    } = formValidation()

    return {
      //  Options
      statusOptions,

      //  Image
      refInputEl,
      previewEl,
      inputImageRenderer,
      removeIcon,

      //  Form
      getValidationState,
    }
  },
}
</script>
