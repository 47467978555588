import { procModuleAPI, fetchModulesAPI, fetchModuleAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
    modules: [],
  },
  getters: {
    modules(state) {
      return state.modules
    },
    moduleOptions(state) {
      if (state.modules.length <= 0) {
        return []
      }
      return state.modules.map(obj => ({
        label: obj.description,
        value: obj.moduleId,
      }))
    },
  },
  mutations: {
    SET_MODULES(state, data) {
      state.modules = data
    },
  },
  actions: {
    async fetchModules(ctx, queryParams) {
      try {
        ctx.commit('SET_MODULES', [])
        const response = await this.$axios.get(fetchModulesAPI, { params: queryParams })
        ctx.commit('SET_MODULES', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchModule(ctx, id) {
      try {
        return await this.$axios.get(`${fetchModuleAPI}/${id}`)
      } catch (error) {
        return error
      }
    },
    async procModule(ctx, params) {
      try {
        return await this.$axios.post(procModuleAPI, params)
      } catch (error) {
        return error
      }
    },
  },
}
